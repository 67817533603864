<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title>
        Asignar locales
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-row class="pt-2">
          <v-col cols="12" md="5">
            <v-autocomplete
              v-model="lista_1"
              label="Lista"
              item-text="nombre"
              item-value="codigo"
              :items="listas.filter(l => l.inhabilitada == 0 && l.codigo != lista_2)"
              hide-details
              outlined
              dense
              @change="get_locales(lista_1, 'locales_1'); get_locales(lista_2, 'locales_2')"
            ></v-autocomplete>
            <v-card :disabled="!(lista_1 != null && lista_2 != null)" class="mt-4">
              <v-card-title class="px-0 py-1" style="font-size: 18px">
                <v-btn
                  icon
                  @click="select_all(locales_1)"
                >
                  <v-icon>
                    {{
                      selected.find(item => locales_1.find(i => i.codigo_bb == item.codigo_bb))
                      ? (selected.length == locales_1.length ? 'fas fa-check-square' : 'fas fa-minus-square')
                      : 'far fa-square'
                    }}
                  </v-icon>
                </v-btn>
                Locales lista {{ lista_1 }}
              </v-card-title>
              <v-divider></v-divider>
              <v-card
                height="250px"
                style="overflow-y: auto;"
                flat
              >
                <v-card-text class="pa-0" style="height: 100%">
                  <drop-list
                    :items="locales_1"
                    class="list"
                    style="height: 100%"
                    mode="cut"
                    @insert="onInsert($event, 'locales_1')"
                    @reorder="$event.apply(locales_1)"
                  >
                    <template v-slot:item="{item}">
                      <drag
                        :key="item.nombre"
                        class="item"
                        :class="{ 'selected' : selected.indexOf(item) > -1 }"
                        :data="selection(item)"
                        go-back
                        @click="toggleSelected(locales_1, item)"
                        @cut="remove(locales_1, item)"
                      >
                        {{ item.codigo_bb }} - {{ item.nombre }}
                      </drag>
                    </template>
                    <template v-slot:feedback="{ data }">
                      <template v-if="selected.length > 0">
                        <div v-for="f in data" :key="f.nombre" class="item feedback">{{ f.nombre }}</div>
                      </template>
                      <template v-else>
                        <div :key="data.nombre" class="item feedback">{{ data.nombre }}</div>
                      </template>
                    </template>
                  </drop-list>
                </v-card-text>
              </v-card>
            </v-card>
          </v-col>
          <v-col
            cols="12" md="2"
            class="d-flex px-0"
            :class="$vuetify.breakpoint.mdAndUp ? 'align-center' : 'justify-center'"
          >
            <v-row class="d-flex justify-center text-center" :no-gutters="$vuetify.breakpoint.mdAndUp">
              <v-col cols="2" md="12">
                <v-btn
                  color="primary"
                  title="Quitar todos"
                  class="mb-md-2"
                  :small="$vuetify.breakpoint.smAndDown"
                  :disabled="load || !(lista_1 != null && lista_2 != null)"
                  @click="mover_all(locales_2, locales_1)"
                >
                  <v-icon>fas fa-angle-double-{{ $vuetify.breakpoint.mdAndUp ? 'right' : 'down' }}</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="2" md="12">
                <v-btn
                  color="primary"
                  title="Quitar seleccionado/s"
                  class="mb-md-6"
                  :small="$vuetify.breakpoint.smAndDown"
                  :disabled="load || !(lista_1 != null && lista_2 != null)"
                  @click="mover(locales_2, locales_1)"
                >
                  <v-icon>fas fa-angle-{{ $vuetify.breakpoint.mdAndUp ? 'right' : 'down' }}</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="2" md="12">
                <v-btn
                  color="primary"
                  title="Agregrar seleccionado/s"
                  class="mb-md-2"
                  :small="$vuetify.breakpoint.smAndDown"
                  :disabled="load || !(lista_1 != null && lista_2 != null)"
                  @click="mover(locales_1, locales_2)"
                >
                  <v-icon>fas fa-angle-{{ $vuetify.breakpoint.mdAndUp ? 'left' : 'up' }}</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="2" md="12">
                <v-btn
                  color="primary"
                  title="Agregar todos"
                  class="mb-md-4"
                  :small="$vuetify.breakpoint.smAndDown"
                  :disabled="load || !(lista_1 != null && lista_2 != null)"
                  @click="mover_all(locales_1, locales_2)"
                >
                  <v-icon>fas fa-angle-double-{{ $vuetify.breakpoint.mdAndUp ? 'left' : 'up' }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="5">
            <v-autocomplete
              v-model="lista_2"
              label="Lista"
              item-text="nombre"
              item-value="codigo"
              :items="listas.filter(l => l.inhabilitada == 0 && l.codigo != lista_1)"
              hide-details
              outlined
              dense
              @change="get_locales(lista_2, 'locales_2'); get_locales(lista_1, 'locales_1')"
            ></v-autocomplete>
            <v-card :disabled="!(lista_1 != null && lista_2 != null)" class="mt-4">
              <v-card-title class="px-0 py-1" style="font-size: 18px">
                <v-btn
                  icon
                  @click="select_all(locales_2)"
                >
                  <v-icon>
                    {{
                      selected.find(item => locales_2.find(i => i.codigo_bb == item.codigo_bb))
                      ? (selected.length == locales_2.length ? 'fas fa-check-square' : 'fas fa-minus-square')
                      : 'far fa-square'
                    }}
                  </v-icon>
                </v-btn>
                Locales lista {{ lista_2 }}
              </v-card-title>
              <v-divider></v-divider>
              <v-card
                height="250px"
                style="overflow-y: auto;"
                flat
              >
                <v-card-text class="pa-0" style="height: 100%">
                  <drop-list
                    :items="locales_2"
                    class="list"
                    style="height: 100%"
                    mode="cut"
                    @insert="onInsert($event, 'locales_2')"
                    @reorder="$event.apply(locales_2)"
                  >
                    <template v-slot:item="{ item }">
                      <drag
                        :key="item.nombre"
                        class="item"
                        :class="{ 'selected' : selected.indexOf(item) > -1 }"
                        :data="selection(item)"
                        go-back
                        @cut="remove(locales_2, item)"
                        @click="toggleSelected(locales_2, item)"
                      >
                        {{ item.codigo_bb }} - {{ item.nombre }}
                      </drag>
                    </template>
                    <template v-slot:feedback="{ data }">
                      <template v-if="selected.length > 0">
                        <div v-for="f in data" :key="f.nombre" class="item feedback">{{ f.nombre }}</div>
                      </template>
                      <template v-else>
                        <div :key="data.nombre" class="item feedback">{{ data.nombre }}</div>
                      </template>
                    </template>
                  </drop-list>
                </v-card-text>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" class="pb-0 text-center">
          (*Arraste los locales de acuerdo a la configuración deseada)
        </v-col>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-row class="d-flex justify-end py-4 px-3">
          <v-btn
            color="error"
            class="mt-2 ml-2"
            :disabled="load"
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <BtnConfirmar
            clase="mt-2 ml-2"
            :disabled="!(lista_1 != null && lista_2 != null)"
            :loading="load"
            @action="guardar()"
          />
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Modal para asignar locales a una lista
 * 
 */
import { Drag, DropList } from "vue-easy-dnd"
import BtnConfirmar from '../../util/BtnConfirmar'

export default {
  data () {
    return {
      selectedList: 0,
      load: false,
      listas: [],
      selected: [],
      locales_1: [],
      locales_2: [],
      lista_1: null,
      lista_2: null,
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('listas/get_listas')
      .then((res) => {
        this.listas = res.data
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error',
        })
      })
    this.$store.state.loading = false
  },
  props: {
    value: Boolean,
    p_lista: Number, // codigo de una lista, sirve para cargar de antemano los locales de una lista cuando abre el modal, si no envia nada no carga ningun local
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    Drag,
    DropList,
    BtnConfirmar
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.selectedList = 0
        this.selected = []
        this.locales_1 = []
        this.locales_2 = []
        this.lista_1 = null
        this.lista_2 = null
        if (this.p_lista) {
          // hace una copia del prop
          this.lista_1 = JSON.parse(JSON.stringify(this.p_lista))
          // obtiene los locales de la lista y los asigna al primer array
          await this.get_locales(this.lista_1, 'locales_1')
        }
      }
    }
  },
  methods: {
    async guardar () {
      let mensaje = {
        texto: '<div style="text-align: left">',
        error: 0
      }

      this.load = true
      // obtiene los locales del array locales_2 que fueron cambiados a la lista_1
      let locales_2_en_lista_1 = this.locales_1.filter(l => l.lista_cod == this.lista_2)
      // obtiene los locales del array locales_1 que fueron cambiados a la lista_2
      let locales_1_en_lista_2 = this.locales_2.filter(l => l.lista_cod == this.lista_1)

      // solo actualiza los locales que fueron modificados a su nueva lista
      await this.actualizar_locales(locales_2_en_lista_1, this.lista_1, mensaje)
      await this.actualizar_locales(locales_1_en_lista_2, this.lista_2, mensaje)
      this.load = false

      // arma un mensaje personalizado
      const size = locales_2_en_lista_1.length + locales_1_en_lista_2.length
      let icono = 'success'
      let titulo = `Cambio de listas finalizado correctamente <p style="font-size: 22px; margin-top: 12px">Se cambiaron de lista ${size} locales</p>`
      if (mensaje.error > 0) {
        icono = 'warning'
        titulo = `Cambio de listas finalizado, no se pudo cambiar la lista de algunos locales
                  <p style="font-size: 22px; margin-top: 12px; margin-bottom: 0px">Correctos: ${size - mensaje.error} <br/> Con error: ${mensaje.error}</p>`
      }

      this.dialog = false

      await this.$swal.fire({
        icon: icono,
        title: titulo,
        html: mensaje.texto + '</div>',
        width: '700px'
      })

    },
    async actualizar_locales (array, lista, mensaje) {
      for (let index = 0; index < array.length; index++) {
        mensaje.texto += '<p style="font-size: 18px; margin-top: 8px; margin-bottom: 0px">'
        const local = array[index]
        await this.$store.dispatch('listas/cambiar_local', {
          lista_codigo: lista,
          local_codigo: local.codigo,
          codigo_bb: local.codigo_bb
        })
          .then((res) => {
            mensaje.texto += '<i class="fas fa-check" style="color: green"></i>' + res.message
          })
          .catch((error) => {
            mensaje.texto += '<i class="fas fa-times" style="color: red; margin-right: 5px"></i>' + error.message
            mensaje.error += 1
          })
        mensaje.texto += '</p>'
      }
    },
    async get_locales (lista, nombre_array) {
      this.selectedList = 0
      this.selected = []
      this[nombre_array] = []
      if (lista) {
        this.$store.state.loading = true
         await this.$store.dispatch('listas/get_locales_lista', lista)
          .then((res) => {
            this[nombre_array] = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        this.$store.state.loading = false
      }
    },
    // metodos de los botones
    async mover (lista_agregar, lista_sacar) {
      await this.selected.forEach(async (item) => {
        if (lista_sacar.find(i => i.codigo_bb == item.codigo_bb)) {
          const index = lista_sacar.indexOf(item)
          await lista_sacar.splice(index, 1)
          await lista_agregar.push(item)
        }
      })
      this.selected = []
    },
    async mover_all (lista_agregar, lista_sacar) {
      await lista_sacar.forEach(async (item) => {
        await lista_agregar.push(item)
      })
      await lista_sacar.splice(0, lista_sacar.length)
      this.selected = []
    },
    select_all (items) {
      if (this.selected.length == 0) {
        items.forEach(item => {
          this.toggleSelected(items, item)
        })
      } else {
        this.selected = []
      }
    },
    // metodos del dnd
    selection(item) {
      return this.selected.length > 0 ? this.selected : item
    },
    onInsert(event, listName = "locales_1") {
      if (event.data.length > 0) {
        event.data.forEach((e, idx) => {
          if (!this[listName].find(item => item.codigo_bb == e.codigo_bb)) {
            this[listName].splice(event.index + idx, 0, e)
          }
        })
      } else {
        if (!this[listName].find(item => item.codigo_bb == event.data.codigo_bb)) {
          this[listName].splice(event.index, 0, event.data)
        }
      }
      this.selected = []
    },
    remove(array, value) {
      if (this.selected.length > 0) {
        this.selected.forEach(e => {
          if (array.find(item => item.codigo_bb == e.codigo_bb)) {
            let index = array.indexOf(e)
            array.splice(index, 1)
          }
        })
      } else {
        let index = array.indexOf(value)
        array.splice(index, 1)
      }
    },
    toggleSelected(listName, item) {
      if (listName !== this.selectedList) {
        this.selected = []
        this.selectedList = listName
      }
      const index = this.selected.indexOf(item)
      if (index > -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(item)
      }
    }
  }
}
</script>

<style>
.list {
  padding: 1px;
}
.item {
  padding: 2px 6px;
  margin: 3px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.selected {
  background-color:  var(--v-primary-base);
  color: #ffff;
}
.feedback {
  border: 1px dashed var(--v-primary-base);
}
.drag-image {
  background-color: rgb(220, 255, 220);
  transform: translate(-50%, -50%);
}
</style>