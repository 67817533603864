<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel" flat>
        <v-expansion-panel readonly>
          <v-expansion-panel-content class="py-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" md="2" class="py-1">
                  Código
                  <v-text-field
                    v-model.trim="filtro.codigo"
                    tabindex="1"
                    type="number"
                    hide-details
                    autofocus
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Rubro
                  <v-autocomplete
                    v-model="filtro.rubros"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="p_rubros.filter(rubro => rubro.inhabilitado != 1)"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                    @blur="get_categorias()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Categoria
                  <v-autocomplete
                    v-model="filtro.categorias"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="categorias.filter(cat => cat.inhabilitado != 1)"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                    @blur="get_lineas()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Linea
                  <v-autocomplete
                    v-model="filtro.lineas"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="lineas"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="5" md="1" class="d-flex justify-center align-center py-1">
                  <v-switch
                    v-model="filtro.vigente"
                    label="Vigente"
                    tabindex="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <BtnFiltro
                  clase="mt-md-0 mt-4"
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-divider></v-divider>
      <v-data-table
        class="cebra mt-2"
        :headers="headers"
        :items="precios"
        :loading="load"
        :search="search"
        :footer-props="{'items-per-page-options':[15, 30, 50, 100]}"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="9" md="10" class="d-flex justify-start align-center">
              <DownloadDataTable
                :data="precios"
                :name="`Precios lista ${p_lista}`"
                :headers="headers_excel"
              />
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <v-text-field
                v-model="search"
                label="Buscar"
                hide-details
                single-line
                outlined
                dense
              >
                <template v-slot:append>
                  <v-icon
                    class="mt-1"
                    title="Buscar en los datos filtrados"
                    small
                  >
                    fas fa-search
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </template>
        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
        <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { format_money, order_list_by } from '../../../util/utils'
import BtnFiltro from '../../util/BtnFiltro'
import DownloadDataTable from '../../../components/util/DownloadDataTable'

export default {
  data () {
    return {
      panel: 0,
      search: '',
      load: false,
      categorias: [],
      lineas: [],
      precios: [],
      headers: [
        { text: 'Código', value: 'codigo', align: 'end' },
        { text: 'Artículo', value: 'nombre' },
        { text: 'Precio', value: 'precio', align: 'end', formatter: format_money },
        { text: 'Marca', value: 'marca_nombre' },
        { text: 'Modelo', value: 'modelo_nombre' },
        { text: 'Rubro', value: 'rubro_nombre' },
        { text: 'Categoria', value: 'categoria_nombre' },
        { text: 'Linea', value: 'linea_nombre' },
        { text: 'IVA', value: 'iva', align: 'end' },
        { text: 'Vigencia', value: 'fecha' },
      ],
      headers_excel: {
        'CÓDIGO': 'codigo',
        'ARTÍCULO': 'nombre',
        'PRECIO': 'precio',
        'MARCA': 'marca_nombre',
        'MODELO': 'modelo_nombre',
        'RUBRO': 'rubro_nombre',
        'CATEGORÍA': 'categoria_nombre',
        'LÍNEA': 'linea_nombre',
        'IVA': 'iva',
        'VIGENCIA': 'fecha'
      },
      filtro: {
        codigo: null,
        nombre: '',
        rubros: null,
        categorias: null,
        lineas: null,
        vigente: 1
      }
    }
  },
  props: {
    p_lista: Number,
    p_rubros: Array
  },
  components: {
    BtnFiltro,
    DownloadDataTable
  },
  watch: {
    p_lista () {
      // si cambia de lista limpia todo
      this.precios = []
      this.search = ''
      this.limpiar()
    }
  },
  methods: {
    async buscar () {
      if (this.p_lista) {
        this.search = ''
        this.precios = []
        this.$store.state.loading = true
        // setea la data para la consulta
        let data = JSON.parse(JSON.stringify(this.filtro))
        data.lista = this.p_lista
        // hace la consulta
        await this.$store.dispatch('listas/get_precios_lista', data)
          .then((res) => {
            this.precios = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        this.$store.state.loading = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'No se puede realizar la consulta sin un código de lista',
          color: 'warning',
        })
      }
    },
    async get_categorias () {
      this.$store.state.loading = true
      this.categorias = []
      this.lineas = []
      this.filtro.categorias = null
      this.filtro.lineas = null
      const rubros = this.filtro.rubros
      if (rubros) {
        for (let index = 0; index < rubros.length; index++) {
          const rubro = rubros[index]
          await this.$store.dispatch('categorias/get_categorias', { rubro: rubro })
            .then((res) => {
              const categorias = res.data
              categorias.forEach(categoria => {
                this.categorias.push(categoria)
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error',
              })
            })
        }
        order_list_by(this.categorias, 'nombre')
      }
      this.$store.state.loading = false
    },
    async get_lineas () {
      this.$store.state.loading = true
      this.lineas = []
      this.filtro.lineas = null
      const categorias = this.filtro.categorias
      if (categorias) {
        for (let index = 0; index < categorias.length; index++) {
          const categoria = categorias[index]
          await this.$store.dispatch('lineas/get_lineas', { categoria: categoria })
            .then((res) => {
              const lineas = res.data
              lineas.forEach(linea => {
                this.lineas.push(linea)
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error',
              })
            })
        }
        order_list_by(this.lineas, 'nombre')
      }
      this.$store.state.loading = false
    },
    limpiar () {
      this.categorias = []
      this.lineas = []
      this.filtro = {
        codigo: null,
        nombre: '',
        rubros: null,
        categorias: null,
        lineas: null,
        vigente: 1
      }
    }
  }
}
</script>