<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12" class="py-1">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="open_dialog(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="6" md="2" class="py-1">
                  Código
                  <v-text-field
                    v-model.trim="filtro.codigo"
                    tabindex="1"
                    type="number"
                    hide-details
                    autofocus
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="py-1">
                  Tipo
                  <v-autocomplete
                    v-model="filtro.tipo"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="tipos.filter(t => t.inhabilitado != 1)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre_corto"
                    item-value="id"
                    tabindex="1"
                    :items="empresas"
                    hide-details
                    outlined
                    dense
                    @change="get_locales()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursales"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="$store.state.sucursales"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                    @change="get_locales()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="5" class="py-1">
                  Punto de venta
                  <v-autocomplete
                    v-model="filtro.locales"
                    item-text="nombre"
                    item-value="local_accesorios"
                    tabindex="1"
                    :items="locales"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="6" md="2" class="d-flex  align-center py-1" :class="pantallaChica ? '' : ''">
                  <v-switch
                    v-model="filtro.inhabilitada"
                    label="Inhabilitada"
                    tabindex="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <v-col cols="6" sm="6" md="5" class="d-flex justify-end py-1">
                  <BtnFiltro
                    clase=""
                    :loading="load"
                    @clear="limpiar()"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expand-transition>
        <div
          v-if="selected.length > 0 && tab != 0"
          class="d-flex align-center justify-center mt-2 secondary--text font-weight-medium body-1"
        >
          LISTA {{ selected[0].codigo }} - {{ selected[0].nombre }}
        </div>
      </v-expand-transition>

      <v-card>
        <v-tabs
          v-model="tab"
          :vertical="$vuetify.breakpoint.xs"
          class="mt-2"
        >
          <v-tab>
            Listas
          </v-tab>
          <v-tab
            :disabled="selected.length == 0"
          >
            Precios
          </v-tab>
          <v-tab
            :disabled="selected.length == 0"
          >
            Locales
          </v-tab>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-spacer v-if="$vuetify.breakpoint.xl"></v-spacer>
          <v-slide-y-transition>
            <v-col
              v-if="tab != 1"
              class="py-2"
            >
              <v-text-field
                v-model="search"
                label="Buscar"
                hide-details
                single-line
                outlined
                dense
              >
                <template v-slot:append>
                  <v-icon
                    class="mt-1"
                    title="Buscar en los datos filtrados"
                    small
                  >
                    fas fa-search
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-slide-y-transition>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-data-table
              v-model="selected"
              class="cebra"
              item-key="codigo"
              :headers="headers_lista"
              :items="listas"
              :loading="load"
              :search="search"
              single-select
              show-select
              dense
              @click:row="rowClick"
            >
              <template v-slot:[`item.marckup`]="{ item }">
                <v-icon
                  v-if="item.marckup == 1"
                  color="success"
                  title="Marckup"
                  small
                >
                  fas fa-check-circle
                </v-icon>
              </template>
              <template v-slot:[`item.ecommerce`]="{ item }">
                <v-icon
                  v-if="item.ecommerce == 1"
                  color="success"
                  title="Ecommerce"
                  small
                >
                  fas fa-check-circle
                </v-icon>
              </template>
              <template v-slot:[`item.catalogo`]="{ item }">
                <v-icon
                  v-if="item.catalogo == 1"
                  color="success"
                  title="Catálogo"
                  small
                >
                  fas fa-check-circle
                </v-icon>
              </template>
              <template v-slot:[`item.inhabilitada`]="{ item }">
                <span
                  v-text="item.inhabilitada ? 'SI' : 'NO'"
                ></span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-if="!item.inhabilitada"
                  color="primary"
                  title="Editar"
                  class="mr-2"
                  small
                  @click.stop="open_dialog(item)"
                >
                  fas fa-pen
                </v-icon>
                <BtnConfirmar
                  :icono="item.inhabilitada ? 'fas fa-check' : 'fas fa-ban'"
                  :color="item.inhabilitada ? 'success' : 'error'"
                  :title="item.inhabilitada ? 'Habilitar' : 'Inhabilitar'"
                  :texto="item.inhabilitada
                    ? `¿Desea habilitar la lista <strong>${item.nombre}</strong>?`
                    : `¿Desea inhabilitar la lista <strong>${item.nombre}</strong>?`"
                  :icon_button="true"
                  :small="true"
                  @action="item.inhabilitada ? habilitar(item) : inhabilitar(item)"
                />
              </template>
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos para los filtros seleccionados
                </v-alert>
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab-item>
            <PreciosXLista
              v-if="selected.length > 0"
              :p_lista="selected[0].codigo"
              :p_rubros="rubros"
            />
          </v-tab-item>

          <v-tab-item>
            <v-data-table
              class="cebra"
              :headers="headers_local"
              :items="locales_lista"
              :loading="load"
              :search="search"
              dense
            >
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos para la lista seleccionada
                </v-alert>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
    <v-col 
      v-if="tab == 0"
      cols="12" md="12" class="py-1 d-flex flex-wrap" :class="pantallaChica ? 'justify-center' : 'justify-end'">
      <v-btn
        class="ml-3 mt-2"
        :block="pantallaChica"
        color="orange"
        dark
        @click="objModal.dialog_list_var = true"
      >
        <v-icon left>fas fa-list-ul</v-icon>
        Listas Variables
      </v-btn>
      <v-btn
        class="ml-3 mt-2"
        color="purple"
        dark
        :block="pantallaChica"
        @click="dialog_locales = true"
      >
        <v-icon left>fas fa-store</v-icon>
        Asignar locales
      </v-btn>
      <v-btn
        class="ml-3 mt-2"
        color="primary"
        title="Buscando por filtros"
        to="/actualizar-precios"
        target="_blank"
        dark
        :block="pantallaChica"
      >
        <v-icon left>fas fa-filter</v-icon>
        Actualizar precios
      </v-btn>
      <v-btn
        class="ml-3 mt-2"
        color="green darken-3"
        title="Mediante un archivo"
        dark
        @click="dialog_precio = true"
        :block="pantallaChica"
      >
        <v-icon left>fas fa-file-upload</v-icon>
        Actualizar precios
      </v-btn>
    </v-col>
    <AsignarLocales
      v-model="dialog_locales"
      :p_lista="selected[0] ? selected[0].codigo : null"
    />
    <ActualizarPreciosArchivo
      v-model="dialog_precio"
    />
    <Lista
      v-model="dialog"
      :p_nueva="nueva"
      :p_lista="lista"
      :p_tipos="tipos"
      @editar="editar"
    />
    <ModalListasVariablesVue
      v-model="objModal.dialog_list_var"
      :datos="objModal"
    />
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { order_list_by } from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import PreciosXLista from '../../components/generales/listas/PreciosXLista'
import Lista from '../../components/generales/listas/Lista'
import ActualizarPreciosArchivo from '../../components/generales/listas/ActualizarPreciosArchivo'
import AsignarLocales from '../../components/generales/listas/AsignarLocales'
import ModalListasVariablesVue from '../../components/generales/listas/ModalListasVariables.vue'

export default {
  data () {
    return {
      tab: null,
      panel: 0,
      search: '',
      load: false,
      nueva: false,
      dialog: false,
      dialog_precio: false,
      dialog_locales: false,
      objModal: { dialog_list_var: false, },
      tipos: [],
      listas: [],
      rubros: [],
      locales: [],
      selected: [],
      locales_lista: [],
      headers_lista: [
        { text: 'Código', value: 'codigo', align: 'end' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Tipo', value: 'tipo_nombre' },
        { text: 'Precio Vta. Aut.', value: 'marckup', sortable: false, filterable: false, align: 'center'},
        { text: 'Ecommerce', value: 'ecommerce', sortable: false, filterable: false, align: 'center' },
        { text: 'Catálogo', value: 'catalogo', sortable: false, filterable: false, align: 'center' },
        { text: 'Inhabilitada', value: 'inhabilitada', sortable: false, filterable: false },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
      ],
      headers_local: [
        { text: 'Código', value: 'codigo_bb', align: 'end' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Sucursal', value: 'sucursal_nombre' },
      ],
      lista: {},
      filtro: {
        codigo: null,
        nombre: '',
        tipo: null,
        empresa: null,
        sucursales: null,
        locales: [],
        inhabilitada: 0
      },
      pantallaChica: this.$vuetify.breakpoint.xs,
    }
  },
  async created () {
    // si tiene solo 1 empresa la selecciona
    if (this.una_empresa) {
      this.filtro.empresa = this.empresas[0].id
    }
    // obtiene los tipos de listas
    this.$store.state.loading = true
    this.tipos = []
    await this.$store.dispatch('listas/get_tipos')
      .then((res) => {
        this.tipos = res.data
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error',
        })
      })
    this.$store.state.loading = false
    this.buscar()
  },
  computed: {
    ...mapGetters(['una_empresa']),
    ...mapState(['empresas'])
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    PreciosXLista,
    Lista,
    ActualizarPreciosArchivo,
    AsignarLocales,
    ModalListasVariablesVue
  },
  watch: {
    async tab (val) {
      this.search = ''
      switch (val) {
        case 1:
          // solo si no trajo los rubros los vuelve a traer
          if (this.rubros.length == 0) {
            this.$store.state.loading = true
            this.rubros = []
            await this.$store.dispatch('rubros/get_rubros')
              .then((res) => {
                this.rubros = res.data
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error',
                })
              })
            order_list_by(this.rubros, 'nombre')
            this.$store.state.loading = false
          }
          break
        case 2:
          // obtiene los locales de la lista seleccionada
          this.get_locales_lista(this.selected[0].codigo)
          break
      }
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  },
  methods: {
    async get_locales_lista (codigo) {
      this.$store.state.loading = true
      this.locales_lista = []
      await this.$store.dispatch('listas/get_locales_lista', codigo)
        .then((res) => {
          this.locales_lista = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async habilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('listas/habilitar', item.codigo)
        .then((res) => {
          // actualiza la lista en la lista
          item.inhabilitada = 0
          // muestra mensaje
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success',
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async inhabilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('listas/inhabilitar', item.codigo)
        .then((res) => {
          // actualiza la lista en la lista
          item.inhabilitada = 1
          // muestra mensaje
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success',
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      this.load = true
      this.tab = 0
      this.search = ''
      this.listas = []
      this.selected = []
      this.locales_lista = []
      await this.$store.dispatch('listas/get_listas', this.filtro)
        .then((res) => {
          this.listas = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.load = false
    },
    editar (item) {
      // actualiza la lista en la lista xd
      let lista = this.listas.find(lis => lis.codigo == item.codigo)
      Object.assign(lista, item)
    },
    open_dialog (item) {
      if (item) {
        this.nueva = false
        this.lista = JSON.parse(JSON.stringify(item))
      } else {
        this.nueva = true
        this.lista = {}
      }
      this.dialog = true
    },
    // @click:row="rowClick" en el datatable
    rowClick (item, row) {
      let selectState = (row.isSelected) ? false : true
      row.select(selectState)
    },
    get_locales () {
      this.locales = []
      this.filtro.locales = []
      const empresa = this.filtro.empresa
      if (empresa && this.filtro.sucursales) {
        this.filtro.sucursales.forEach(sucursal => {
          let locales_sucursal = this.$store.state.locales.filter(local => local.sucursal == sucursal && local.empresa == empresa)
          locales_sucursal.forEach(local => {
            this.locales.push(local)
          })
        })
      }
    },
    limpiar () {
      this.locales = []
      this.filtro = {
        codigo: null,
        nombre: '',
        tipo: null,
        empresa: null,
        sucursales: null,
        locales: [],
        inhabilitada: 0
      }
    }
  }
}
</script>