<template>
<div>
<v-dialog
    v-model="activo"
    width="650"
    :persistent="activo"
    scrollable
  >
    <v-card>
      <v-card-title class="">
        <div style="font-size: 20px">{{ nuevo? 'Nueva Lista Variable' : 'Lista *'+listaVariable.nombre_lista_var+'*' }}</div>
        <v-spacer></v-spacer>
        <v-btn right icon @click="activo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-3">
        <v-form @submit.prevent="guardarEmit()">
          <v-row class="pt-2">
            <v-col cols="12" sm="6" md="6" class="py-1">
              Nombre
              <v-text-field
                v-model="listaCopia.nombre_lista_var"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-1 ">
              Sucursal
              <v-autocomplete
                v-model="listaCopia.sucursal_codigo"
                item-text="nombre"
                item-value="id"
                :items="sucursales"
                hide-details
                outlined
                dense
                @change="encontrarNombre()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="2" class="py-1">
              Porcentaje
              <v-text-field
                v-model="listaCopia.porcentaje"
                type="number"
                suffix="%"
                hide-details
                outlined
                dense
                @change="controlarPorcentaje()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="8" class="py-1 d-flex align-center">
              <v-checkbox
                v-model="selecTodosDias"
                hide-details
                small
                title="Seleccionar todos"
                @change="seleccionarTodosDias()"
              ></v-checkbox>
              <div style="width: 100%;">
                Dias
                <v-autocomplete
                  v-model="listaCopia.dias"
                  item-text="nombre_dia"
                  item-value="id_dia"
                  :items="dias"
                  hide-details
                  multiple
                  return-object
                  chips
                  deletable-chips
                  outlined
                  dense
                  clearable
                >
                </v-autocomplete>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-1">
              <v-switch
                v-model="listaCopia.inhabilitado"
                label="Inhabilitado"
                class="pt-2 ma-0"
                :true-value="1"
                :false-value="0"
                hide-details
                dense
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!-- BOTONES -->
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          color="secondary"
          @click="activo = false"
        >cancelar</v-btn>
        <BtnConfirmarVue
          :texto="(nuevo? 'Grabar nueva lista variable. ' : `Aplicar cambios a la lista variable: ${listaCopia.nombre_lista_var}. `) + '¿Confirma esta accion?'"
          :nombre="nuevo? 'GRABAR' : 'GUARDAR CAMBIOS'"
          :loading="load"
          @action="guardarEmit()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import BtnConfirmarVue from '../../util/BtnConfirmar.vue';

export default {
  name: 'ModalAgregarEditarListaVariable',
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default: {}
    }  
  },
  computed:{
    activo:{
      get(){
        return this.datos.activo;
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    nuevo:{
      get(){
        return this.datos.nuevo;
      },
    },
    listaVariable:{
      get(){
        return this.datos.listaVariable;
      },
    },
    dias:{
      get(){
        return [...this.datos.dias];
      },
    },
    sucursales:{
      get(){
        return this.datos.sucursales;
      },
    },
  },
  data(){
    return{
      listaCopia: {},
      load: false,
      selecTodosDias: false,
      pantallaChica: this.$vuetify.breakpoint.xs,
    }
  },
  created(){
  },
  methods:{
    async init(){
      this.$store.state.loading = true;
      const res = await this.$store.dispatch('listas/getDiasListaVar',{ id_lista_var: this.listaCopia.id_lista_var })
      this.$store.state.loading = false;

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', })
      }
      this.listaCopia.dias = res.dias;
    },
    validarCampos(){
      let error = {}
      if(!this.listaCopia.nombre_lista_var){
        error.text = 'Debe ingresar el nombre de la lista variable.';
        error.color = 'warning';
        return error;
      }
      if(!this.listaCopia.sucursal_codigo || this.listaCopia.sucursal_codigo == 0){
        error.text = 'Debe seleccionar la sucursal.';
        error.color = 'warning';
        return error;
      }
      if(!this.listaCopia.porcentaje && this.listaCopia.porcentaje != 0){
        error.text = 'Debe ingresar el porcentaje.';
        error.color = 'warning';
        return error;
      }
      if(this.listaCopia.dias.length == 0){
        error.text = 'Debe seleccionar al menos un día.';
        error.color = 'warning';
        return error;
      }

      return error;
    },
    guardarEmit(){
      let error = this.validarCampos();
      if(Object.keys(error).length != 0){
        return this.$store.dispatch('show_snackbar', error)
      }
      
      this.$emit('guardarCambios', this.listaCopia, this.nuevo);
      this.activo = false;
    },
    controlarPorcentaje(){
      if(this.listaCopia.porcentaje){
        if(this.listaCopia.porcentaje < 0){
          this.listaCopia.porcentaje = 0;
        }
        if(this.listaCopia.porcentaje > 100){
          this.listaCopia.porcentaje =100
        }
      }
    },
    limpiar(){
      this.listaCopia = {
        dias: [],
        id_lista_var: null,
        inhabilitado: null,
        nombre_lista_var: null,
        porcentaje: null,
        sucursal_codigo: null,
        sucursal_nombre: null,
      }
    },
    /*armarListasDias(){
      let diasSelect = this.listaCopia.dias.split(', ');
      let strArr = []
      for(let i in diasSelect){
        //i -> indice
        let aux = this.dias.filter(e => e.nombre_dia == diasSelect[i]);
        if(aux.length == 1){
          strArr.push(aux[0]);
        }
      }
      this.listaCopia.dias = strArr;
    },*/
    seleccionarTodosDias(){
      if(this.selecTodosDias){
        this.listaCopia.dias = this.dias;
      }else{
        this.listaCopia.dias = [];
      }
    },
    encontrarNombre(){
      if(this.listaCopia.sucursal_codigo){
        this.listaCopia.sucursal_nombre = this.sucursales.filter(e => e.id == this.listaCopia.sucursal_codigo)[0].nombre;
      }
    },
  },
  components:{
    BtnConfirmarVue
  },
  watch:{
    activo: async function(val){
      if(val){
        this.listaCopia = JSON.parse(JSON.stringify(this.listaVariable));
        if(!this.nuevo){
          //this.armarListasDias();
          await this.init();
          if(this.listaCopia.dias.length == 7){
            this.selecTodosDias = true;
          }
        }
      }else{
        //this.listaVariable = JSON.parse(JSON.stringify(this.listaCopia));
        this.selecTodosDias = false;
      }
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  }
}
</script>

<style>

</style>