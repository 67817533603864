<template>
  <v-dialog
    v-model="dialog"
    max-width="350px"
    :persistent="load"
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ p_nueva ? 'Nueva lista' : 'Editar lista' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8">
        <v-form ref="form">
          <v-row class="pb-2">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="lista.nombre"
                label="Nombre"
                :rules="[rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-autocomplete
                v-model="lista.tipo_codigo"
                label="Tipo"
                item-text="nombre"
                item-value="codigo"
                :rules="[rules.required]"
                :items="p_tipos.filter(t => t.inhabilitado != 1)"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-checkbox
                v-model="lista.marckup"
                label="Precio Venta Automático"
                :true-value="1"
                :false-value="null"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-checkbox
                v-model="lista.ecommerce"
                label="Ecommerce"
                :true-value="1"
                :false-value="null"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-checkbox
                v-model="lista.catalogo"
                label="Catálogo"
                :true-value="1"
                :false-value="null"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end py-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar o crear una lista
 * 
 *  Notas: este componente emite acciones (@nueva, @editar) al padre caundo se crea o edita una lista (en caso de estar definidas)
 *         todos los props son obligatorios
 */
import BtnConfirmar from '../../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      lista: {},
      rules: {
        required: value => !!value || 'Campo requerido',
      }
    }
  },
  props: {
    value: Boolean,
    p_nueva: Boolean, // para editar una lista enviar en false, para una lista nueva en true
    p_lista: Object,  // en caso de ser nueva enviar el objeto vacio {}
    p_tipos: Array    // array de todos los tipos
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      if (val) {
        if (this.p_nueva) {
          // si es nueva setea los campos en null
          this.lista = {
            codigo: null,
            nombre: null,
            tipo_codigo: null,
            marckup: null,
            ecommerce: null,
            catalogo: null
          }
        } else {
          // crea una copia del prop p_lista para editarlo
          this.lista = JSON.parse(JSON.stringify(this.p_lista))
        }
      } else {
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.load = true
        if (this.p_nueva) {
          await this.$store.dispatch('listas/nueva', this.lista)
            .then((res) => {
              this.dialog = false
              // emite la accion 'nueva' definifa en el padre
              this.$emit('nueva', res.lista)
              // muestra mensaje
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success',
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error',
              })
            })
        } else {
          // solo edita si realmente se modifico la lista
          if (JSON.stringify(this.lista) != JSON.stringify(this.p_lista)) {
            await this.$store.dispatch('listas/editar', this.lista)
              .then((res) => {
                this.dialog = false
                // hace un UPPER del nombre de la lista y obtiene el nombre del tipo
                this.lista.nombre = this.lista.nombre.toUpperCase()
                const tipo = this.p_tipos.find(t => t.codigo == this.lista.tipo_codigo)
                if (tipo) {
                  this.lista.tipo_nombre = tipo.nombre
                }
                // emite la accion 'editar' definifa en el padre
                this.$emit('editar', this.lista)
                // muestra mensaje
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success',
                })
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error',
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en la lista: el nombre, el tipo y los demás valores son iguales a los anteriores',
              color: 'warning',
              timeout: 4000
            })
          }
        }
        this.load = false
      }
    }
  }
}
</script>